<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div class="project-page col-sm-12 mar-top-0" style="text-align: left">
          <h2>Gallery</h2>
          <p><a href="/names">Click here</a> to go to the Names page</p>
          <div v-if="loading === true">
            <div class="progress-bar-outer lined thick">
              <div
                class="progress-bar-inner"
                v-bind:style="{ width: progress * 100 + '%' }"
              ></div>
            </div>
          </div>
          <div v-else>
            <div class="project-desc">
              <div class="lined thick inline-block">
                Character
                <div>
                  <select v-model="filter.baseCharacter">
                    <option :value="null">All Base Characters</option>
                    <option
                      v-for="item in baseCharacterTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Mask
                <div>
                  <select v-model="filter.mask">
                    <option :value="null">All Mask Types</option>
                    <option
                      v-for="item in maskTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Eye Color<br />
                <div>
                  <select v-model="filter.eyes">
                    <option :value="null">All Eye Types</option>
                    <option
                      v-for="item in eyeTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Skin Color<br />
                <div>
                  <select v-model="filter.skin">
                    <option :value="null">All Skin Types</option>
                    <option
                      v-for="item in skinTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Item
                <div>
                  <select v-model="filter.item">
                    <option :value="null">All Items</option>
                    <option
                      v-for="item in itemTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Background
                <div>
                  <select v-model="filter.background">
                    <option :value="null">All Backgrounds</option>
                    <option
                      v-for="item in backgroundTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Glyph
                <div>
                  <select v-model="filter.glyph">
                    <option :value="null">All Glyphs</option>
                    <option
                      v-for="item in glyphTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Set
                <div>
                  <select v-model="filter.set">
                    <option :value="null">All Sets</option>
                    <option
                      v-for="item in setTypes"
                      v-bind:value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }} ({{ item.filtered }})
                    </option>
                  </select>
                </div>
              </div>
              <div class="lined thick inline-block">
                Name
                <div>
                  <input type="text" v-model="search" />
                </div>
              </div>
              <div class="lined thick inline-block">
                By ID
                <div>
                  <input
                    type="number"
                    min="0"
                    max="16383"
                    v-model="filter.index"
                  />
                </div>
              </div>
            </div>

            <div style="margin-top: 2em; margin-bottom: 1em">
              {{ filtered }} Hashmasks with these filters. {{ total }} in total.
            </div>

            <div
              id="portfolio-container"
              class="portfolio-container portfolio-overlay dark text-overlay centered row isotope"
            >
              <div
                class="portfolio-item col-md-3 webdesign"
                v-for="item in items"
                :key="item.index"
              >
                <router-link
                  :to="{ name: 'detail', params: { id: item.index } }"
                >
                  <div class="portfolio-content">
                    <div class="portfolio-img-content">
                      <img v-bind:src="item.thumbnailUrl" />
                    </div>
                    <div class="portfolio-text-content">
                      <div class="portfolio-text">
                        <h3>#{{ item.index }}</h3>
                        <h3>{{ item.name || 'Unknown (yet)' }}</h3>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div v-if="end === false">
                <button
                  id="load-more"
                  class="mint-button"
                  v-on:click="loadMore"
                  style="margin-top: 1em; margin-left: 1em"
                >
                  Load more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePictureSwipe from 'vue-picture-swipe';
import Vue from 'vue';
import * as Comlink from 'comlink';

import { getMasks, loadHashMasks } from '../helpers/database.worker';

Vue.component('vue-picture-swipe', VuePictureSwipe);

let debounce = new Date().getTime();
let debounceTimeout;

export default {
  data() {
    return {
      loading: true,
      progress: 0,
      items: [],
      skinTypes: [],
      baseCharacterTypes: [],
      eyeTypes: [],
      maskTypes: [],
      itemTypes: [],
      backgroundTypes: [],
      glyphTypes: [],
      setTypes: [],
      filter: {
        skin: null,
        baseCharacter: null,
        eyes: null,
        mask: null,
        item: null,
      },
      search: '',
      offset: 0,
      total: 0,
      filtered: 0,
      limit: 24,
      pageSize: 24,
      selectedPage: 1,
    };
  },
  methods: {
    async updateMasks() {
      const offset = (this.selectedPage - 1) * this.pageSize;
      const response = await getMasks({
        offset,
        limit: this.limit,
        filter: this.filter,
        search: this.search,
      });
      this.offset = response.offset;
      this.filtered = response.filtered;
      this.total = response.total;
      this.items = response.items;
      this.skinTypes = response.filters.skin;
      this.baseCharacterTypes = response.filters.baseCharacter;
      this.eyeTypes = response.filters.eyes;
      this.maskTypes = response.filters.mask;
      this.itemTypes = response.filters.item;
      this.backgroundTypes = response.filters.background;
      this.glyphTypes = response.filters.glyph;
      this.setTypes = response.filters.set;
    },
    loadUrlParams() {
      this.filter = {
        skin: this.$route.query.skin || null,
        baseCharacter: this.$route.query.baseCharacter || null,
        eyes: this.$route.query.eyes || null,
        mask: this.$route.query.mask || null,
        item: this.$route.query.item || null,
        background: this.$route.query.background || null,
        glyph: this.$route.query.glyph || null,
        set: this.$route.query.set || null,
        index: this.$route.query.maskId || null,
      };
      this.search = this.$route.query.search || '';
    },
    writeUrlParams() {
      this.$router.replace({
        name: 'gallery',
        query: {
          skin: this.filter.skin || undefined,
          baseCharacter: this.filter.baseCharacter || undefined,
          eyes: this.filter.eyes || undefined,
          mask: this.filter.mask || undefined,
          item: this.filter.item || undefined,
          background: this.filter.background || undefined,
          glyph: this.filter.glyph || undefined,
          set: this.filter.set || undefined,
          maskId: this.filter.index || undefined,
          search: this.search || undefined,
        },
      });
    },
    infiniteScroll() {
      const observer = new IntersectionObserver((entries) => {
        const [loadMoreButton] = entries;
        if (loadMoreButton.isIntersecting) {
          this.limit = this.limit + this.pageSize;
          this.updateMasks();
        }
      });
      observer.observe(document.querySelector('#load-more'));
    },
    loadMore() {
      this.limit = this.limit + this.pageSize;
      this.updateMasks();
    },
    onFilterChange() {
      this.limit = this.pageSize;
      this.offset = 0;
      this.writeUrlParams();
      this.updateMasks();
    },
  },
  async mounted() {
    this.loadUrlParams();
    await loadHashMasks(
      Comlink.proxy(({ progress }) => {
        this.progress = progress;
      }),
    ); //
    this.loading = false;
    await this.updateMasks();
    this.infiniteScroll();
  },
  computed: {
    currentPage() {
      return this.offset / this.limit + 1;
    },
    totalPages() {
      return Math.ceil(this.filtered / this.limit);
    },
    allPages() {
      return new Array(this.totalPages).fill(0).map((_, index) => index + 1);
    },
    end() {
      return this.currentPage >= this.totalPages;
    },
  },
  watch: {
    'filter.skin'() {
      this.onFilterChange();
    },
    'filter.baseCharacter'() {
      this.onFilterChange();
    },
    'filter.eyes'() {
      this.onFilterChange();
    },
    'filter.mask'() {
      this.onFilterChange();
    },
    'filter.item'() {
      this.onFilterChange();
    },
    'filter.background'() {
      this.onFilterChange();
    },
    'filter.glyph'() {
      this.onFilterChange();
    },
    'filter.set'() {
      this.onFilterChange();
    },
    'filter.index'() {
      // Clear out the other filters if index filter is active
      if (this.filter.index) {
        this.filter.skin = null;
        this.filter.baseCharacter = null;
        this.filter.eyes = null;
        this.filter.mask = null;
        this.filter.item = null;
      }

      this.onFilterChange();
    },
    search() {
      const now = new Date().getTime();

      if (now - debounce > 1000) {
        if (debounceTimeout) {
          clearTimeout(debounceTimeout);
          debounceTimeout = null;
        }
        this.onFilterChange();
      } else if (!debounceTimeout) {
        debounceTimeout = window.setTimeout(() => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
            debounceTimeout = null;
          }
          this.onFilterChange();
        }, 1000);
      }
    },
    selectedPage() {
      this.updateMasks();
    },
  },
};
</script>
